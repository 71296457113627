// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-animal-grooming-and-bathing-js": () => import("./../../../src/pages/animal-grooming-and-bathing.js" /* webpackChunkName: "component---src-pages-animal-grooming-and-bathing-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-history-js": () => import("./../../../src/pages/our-history.js" /* webpackChunkName: "component---src-pages-our-history-js" */),
  "component---src-pages-premium-quality-food-js": () => import("./../../../src/pages/premium-quality-food.js" /* webpackChunkName: "component---src-pages-premium-quality-food-js" */),
  "component---src-pages-products-we-carry-js": () => import("./../../../src/pages/products-we-carry.js" /* webpackChunkName: "component---src-pages-products-we-carry-js" */)
}

